import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './stylePanier.css';
import './stylefac.css';
import 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
// @mui
import { LoadingButton } from '@mui/lab';
import { 
  Container, 
  Stack, 
  Typography, 
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Button,
  Input,
  InputAdornment,
  TablePagination,
  Card, CardContent
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import { Table } from 'rsuite';
import Iconify from '../components/iconify';
import {GoPlus} from 'react-icons/go';
import { FaMinus } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Badge } from 'rsuite';
import Image from '../components/assets/produit.jpg';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import { apiPanier, apiArticle, entetes, apiCategorie, apiSousCat, entete, apiPanierInfoPV, notify, urlLocal, apiPanierPointVente, apiGestionPaiementMenu } from '../components/API_URL/ApiUrl';
import { removeItemToCard, resetItems } from './redux/action/Actions';
// mock
import PRODUCTS from '../_mock/products';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// ----------------------------------------------------------------------

const { Column, HeaderCell, Cell } = Table;

export default function Facture() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [time, setTime] = useState(new Date());
  const dateDays = time.toLocaleDateString();
  const [totals, setTotal] = useState({});
  const [prixExpediton, setPrixExpediton] = useState(750);
  const [menupaiement, setMenuPaiement] = useState([]);
  const [panierPvValider, setPanierPvValider] = useState([]);
  const [paiementDispo, setPaiementDispo] = useState(null);
  const [option1, setOption1] = useState("");
  const [option1ID, setOption1ID] = useState("");
  const [option2, setOption2] = useState("");
  const [option2ID, setOption2ID] = useState("");
  const [etat, setEtat] = useState("");
  const [etat1, setEtat1] = useState("");
  const [etat2, setEtat2] = useState("");
  const [moyenpaiementID, setMoyenpaiementID] = useState('');

  //Data info facture
  const [Adresse, setAdresse] = useState('')
  const [prixTotal, setPrixTotal] = useState('')
  const [codePostal, setCodePostal] = useState('')
  const [nomclient, setNomclient] = useState('')
  const [telclient, setTelclient] = useState('')
  const [emailclient, setEmailclient] = useState('')

  const items = useSelector(state => state.Reducers.items);
  console.log("Basket1: " + items);
  console.log("Basket2: " + JSON.stringify(items, null, 2));

  let addedItems = [];
  addedItems = items || [];
  console.log('Length: ' + addedItems.length);

  const [quantities, setQuantities] = useState(Array(addedItems.length).fill(1));

  const total = addedItems.reduce((acc, item, index) => acc + item.prix * quantities[index], 0);

  const removeItem = (index) => {
    dispatch(removeItemToCard(index))
  } 

  const onMinus = (index) => {
    const newQuantities = [...quantities];
    newQuantities[index] = Math.max(newQuantities[index] - 1, 1);
    setQuantities(newQuantities);
  };
  const onPlus = (index) => {
      const newQuantities = [...quantities];
      newQuantities[index] = newQuantities[index] + 1;
      setQuantities(newQuantities);
  };

  let qte = [];
  let totalByArcticle = [];
  for (let i = 0; i < items.length; i++) {
    qte[i] = quantities[i];
    totalByArcticle[i] = items[i].prix * quantities[i];
  }

  let tableData = [];
  for (let i = 0; i < items.length; i++) {
      tableData.push({ ...items[i], index: i });
  }

  // Afficher tous les Paiements disponible
  const allPaiementMenu = async () => {
    let token = localStorage.getItem('token');
    try {
        const response = await apiGestionPaiementMenu.get('/', {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
        })
        // Mapper et associer etat_fact et etat_liv ensemble
        const menupaiement = response.data.data.filter(item => item.etat === "activer").map(item => ({
            id: item.id,
            option: item.option,
            etat: item.etat,
        }));
        setPaiementDispo(menupaiement);
        setOption1(menupaiement[0].option);
        setOption1ID(menupaiement[0].id);
        setEtat1(menupaiement[0].etat);
        setOption2(menupaiement[1].option);
        setOption2ID(menupaiement[1].id);
        setEtat2(menupaiement[1].etat);
        console.log(menupaiement[0].option);

    } catch (error) { throw error; }
  };

  // All Panier info
  const allPanierPv = () => {
    const token = localStorage.getItem('token');
    try {
      apiPanierInfoPV.get('/', {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data.paniers);
        setPanierPvValider(res.data.paniers);
      })
      .catch(error => {
        // Gérez les erreurs de la requête
        console.error(error);
      });
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };
  

  const validerFacture = async (e) => {
    e.preventDefault();
    e.persist()
    let token = localStorage.getItem('token');
    let userID = localStorage.getItem('id');
    let prixTotal = total + prixExpediton;
    const itemsWithQuantity = items.map((item, index) => {
        return {
          ...item,
          articleID: item.id_art,
          qte: quantities[index],
          totalByArcticle: item.prix * quantities[index]
        }
    });          
    console.log(items)
    
    try {
        await apiPanierPointVente.post('/', { 
          items: itemsWithQuantity, 
          userID ,
          preTotal: total,
          prixExpediton: prixExpediton,
          nomclient,
          telclient,
          emailclient,
          moyenpaiementID,
          Adresse,
          prixTotal,
          codePostal,
          ref_facture: `FAC/0${userID}/${dateDays}`
        }, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        }).then((res) => {
          if (res) {
            // Réinitialiser le state items
            dispatch(resetItems());
            setNomclient("")
            setTelclient("")
            setEmailclient("")
            setAdresse("")
            setPrixTotal("")
            setCodePostal("")
            notify('success', "Votre facture a été ajouté avec succès")
            setStatus(true)
            window.location.href = "/dashboard/facture";
          }

        }).catch((err) => {
          console.log(err);
          notify('warning', 'Une Erreur s\'est produite')
        })
    } catch (error) { 
      console.log(error)
      notify('', 'Echec de validation de la facture')
    }
  };

  const gradientStyle = {
    // background: 'linear-gradient(to right, #00b09b, #96c93d)',
    background: 'linear-gradient(to right, #ff4e50, #f9d423)',
    color: '#fff',
    padding: '2px 10px',
  };
  const gradientStyleSuccess = {
    background: 'linear-gradient(to right, #00b09b, #96c93d)',
    color: '#fff',
    padding: '2px 10px',
  };
  

  useEffect(() => {
    allPaiementMenu();
    allPanierPv();
  }, []);


  return (
    <>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title> FACTURE| AGS </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Facture
          </Typography>

          <Button onClick={() => window.location.href='/dashboard/products'} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Faire une facture
          </Button> 
        </Stack>
        <ProductCartWidget />

        {items != 0 &&
          <div className='containerGetTotal'>
              <div className='containerAccount'>
                <div className='account'>
                  <p>Nom du client :</p>
                  <TextField
                    name="nomclient"
                    type="text"
                    label="Nom du client"
                    variant="standard"
                    size='lg'
                    value={nomclient}
                    onChange={(e) => setNomclient(e.target.value)}
                    style={{width: "70%"}}
                    InputProps={{
                      style: { 
                        color: '#FFFFFF',
                        borderBottomColor: '#FFFFFF',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: '#FFFFFF' },
                    }}
                    required
                  />
                </div>
                <div className='account'>
                  <p>Numero :</p>
                  <TextField
                    name="telclient"
                    type="number"
                    label="Numero du client"
                    variant="standard"
                    size='lg'
                    value={telclient}
                    onChange={(e) => setTelclient(e.target.value)}
                    style={{width: "70%"}}
                    InputProps={{
                      style: { 
                        color: '#FFFFFF',
                        borderBottomColor: '#FFFFFF',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: '#FFFFFF' },
                    }}
                    required
                  />
                </div>
                <div className='account'>
                  <p>Email : </p>
                  <TextField
                    name="emailclient"
                    type="email"
                    label="Email"
                    variant="standard"
                    size='lg'
                    value={emailclient}
                    onChange={(e) => setEmailclient(e.target.value)}
                    style={{width: "70%"}}
                    InputProps={{
                      style: { 
                        color: '#FFFFFF',
                        borderBottomColor: '#FFFFFF',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: '#FFFFFF' },
                    }}
                    required
                  />
                </div>
              </div>
          </div>
        }

        <br/><br/>

        <Table data={tableData} autoHeight rowHeight={180} style={{marginBottom: "20px", width: "100%"}}>
          <Column width={300} align="center">
              <HeaderCell>Article</HeaderCell>
              <Cell>
                {rowData => (
                    <div className='panierArticle'>
                        <div className='imgPanier'>
                          <img src={`${urlLocal}/image/${rowData.img_art}`} alt='img' width="100%" height="100%"/>
                        </div>
                        <div className='descProduit'>
                          <p style={{fontWeight: "bold", fontSize: "20px", color: "#000000"}}>{rowData.nomarticle}</p>
                          <p style={{  color: "#000000" }}>{rowData.description}</p>
                        </div>
                    </div>
                )}
              </Cell>
          </Column>

          <Column width={300} align="center">
              <HeaderCell>Prix</HeaderCell>
              <Cell style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px"}} dataKey="prix" />
          </Column>

          <Column width={200} align="center">
              <HeaderCell>Quantité</HeaderCell>
              <Cell style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {rowData => { 
                      qte = quantities[rowData.index];
                      return (
                          <div className='containerOp1'>
                              <button onClick={() => onMinus(rowData.index)} className='btnQuantity1'>
                                  <FaMinus size={20}/>
                              </button>
                              <div style={{width: "20px"}}>{quantities[rowData.index]}</div>
                              <button onClick={() => onPlus(rowData.index)} className='btnQuantity1'>
                                  <GoPlus size={20}/>
                              </button>
                          </div>
                      )
                  }}
              </Cell>
          </Column>

          <Column width={200} align="center">
              <HeaderCell>Total</HeaderCell>
                  <Cell style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px", color: "red", fontWeight: "bold"}}>
                      {rowData => {
                          totalByArcticle = rowData.prix * quantities[rowData.index];
                          return (
                              <div className='totalPrice'>
                                  {rowData.prix * quantities[rowData.index]} F CFA
                              </div>
                          )
                      }}
                  </Cell>
          </Column>

          <Column width={100}>
              <HeaderCell>Action</HeaderCell>
              <Cell style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {rowData => (
                      <div className='containerOp1'>
                          <button onClick={() => removeItem(rowData.index)} className='btnQuantity1'>
                              <AiFillDelete size={20}/>
                          </button>
                      </div>
                  )}
              </Cell>
          </Column>
        </Table>

        {items != 0 &&
          <div className='containerGetTotal'>
            <div className='containerAccount'>
                <div className='account'>
                  <p className='labelAccount'>Sous total:</p>
                  <p>{total} F CFA</p>
                </div>
                <div className='account'>
                  <p className='labelAccount'>Expédition:</p>
                  <p>{prixExpediton} F CFA</p>
                </div>
                <div className='account'>
                  <p className='labelAccount'>Total:</p>
                  <p style={{fontWeight: "bolder"}}> {total? total + prixExpediton: 0}F CFA</p>
                </div>
                <div className='account'>
                  <p>Moyen de paiement</p>
                  <FormControl fullWidth variant="standard" style={{width: "70%"}}>
                    <InputLabel style={{color: "#FFFFFF"}} id="demo-simple-select-standard-label">Moyen de paiement</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="state-select"
                      value={moyenpaiementID}
                      onChange={(e) => {
                        setMoyenpaiementID(e.target.value);
                      }}
                      label="--Sélectionner--"
                      required
                    >
                        <MenuItem value={option1ID}>
                          {option1}
                        </MenuItem>
                        <MenuItem value={option2ID}>
                          {option2}
                        </MenuItem>
                    </Select>
                  </FormControl>
                </div> 
                <div className='account'>
                  <p>Adresse</p>
                  <TextField
                    name="Adresse"
                    type="texte"
                    label="Adresse(optionnel)"
                    variant="standard"
                    size='lg'
                    value={Adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                    style={{width: "70%"}}
                    InputProps={{
                      style: { 
                        color: '#FFFFFF',
                        borderBottomColor: '#FFFFFF',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: '#FFFFFF' },
                    }}
                  />
                </div>
                <div className='account'>
                    <p>Code postal</p>
                    <TextField
                      name="codePostal"
                      type="texte"
                      label="Code Postal(optionnel)"
                      variant="standard"
                      size='lg'
                      value={codePostal}
                      onChange={(e) => setCodePostal(e.target.value)}
                      style={{width: "70%"}}
                      InputProps={{
                        style: { 
                          color: '#FFFFFF',
                          borderBottomColor: '#FFFFFF',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                      }}
                    />
                </div>
                <br/>
                <button onClick={validerFacture} className='paiementBtn'>Valider la facture</button>
              </div>
          </div>
        }

        <br/>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Liste de factures
          </Typography>
        </Stack>

        <div style={{width: "100%", display: "flex", flexWrap: "wrap", gap: "8px"}}> 
          {
            panierPvValider && panierPvValider.map(item => (
            <div className='containerLivraison'>
              <div style={{ width: "100%", textAlign: "center" }}>
                <h3>{item.ref_facture}</h3>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{item.nomclient}</span>
                  <span>{item.telclient}</span>
                  <span>{item.emailclient}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Badge
                    style={item.etat_facture === "En attente" ? gradientStyle : gradientStyleSuccess}
                    content={item.etat_facture === "En attente" ? "Facture non validée" : "Facture validée"}
                  />
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
                <NavLink to={`/dashboard/detailfacture/${item.id}`} className='buttonLiv'>Voir detail facture</NavLink>
              </div>
            </div>
          ))}
        </div>
      </Container>

    </>
  );
}
