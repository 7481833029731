import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  articles: PropTypes.array.isRequired,
  onClickUpdate: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickStock: PropTypes.func.isRequired,
  addcard: PropTypes.func.isRequired,
};

export default function ProductList({ articles, onClickUpdate, onClickDelete, onClickStock, addcard, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {articles.map((article) => (
        <Grid key={article.id_art} item xs={12} sm={6} md={3}>
          <ShopProductCard 
            article={article} 
            onClickUpdate={onClickUpdate}  
            onClickDelete={onClickDelete} 
            onClickStock={onClickStock}
            addcard={addcard}
          />
        </Grid>
      ))}
    </Grid>
  );
}
