import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { apiArticle, entete, apiSignUp, apiCategorie, apiSousCat, apiRapportUser } from '../components/API_URL/ApiUrl';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  // Data Rapport User
  const [data, setData] = useState([]);
  // Data Users
  const [user, setUser] = useState([]);
  // Data Article
  const [article, setArticle] = useState([]);
  // Data categorie
  const [categorie, setCategorie] = useState([]);
  // Data Sous categorie
  const [sousCat, setSousCat] = useState([]);

  // Afficher tous les utilisateurs
  const alluser = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSignUp.get('/', {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data.data)
        setUser(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All article
  const allarticle = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticle.get('/', {
          headers: {
              entete,
              Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
        console.log(res.data.data)
        setArticle(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All les catégories
  const allcategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiCategorie.get('/', {
          headers: {
              entete,
              Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
          console.log(res.data.data)
          setCategorie(res.data.data);
      })
    } catch (error) {
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All sous Categories
  const allsouscategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSousCat.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
        console.log(res.data.data)
        setSousCat(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // Rapport utilisateur
  const fetchData = async () => {
    try {
      const result = await apiRapportUser.get('/');
      setData(result.data.data);
      // setDataTypeUser(result.data.data[0]);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    alluser();
    fetchData();
    allarticle();
    allcategorie();
    allsouscategorie();
  }, []);

  return (
    <>
      <Helmet>
        <title> Tableau de board | AGS </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Salut, bienvenue à nouveau
        </Typography>
        <h2> Point de Vente </h2>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Utilisateur" total={user.length? user.length : "0"} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Article" total={article.length? article.length : "0"} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Catégorie" total={categorie.length? categorie.length : "0"} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Sous Catégorie" total={sousCat.length? sousCat.length : "0"} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Rapport des factures de cette période"
              subheader="(+15%) par rapport à l'année passé"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Catégorie A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Catégorie B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Catégorie C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Rapport des utilisateurs"
              chartData={[
                { label: 'Utilisateurs', value: data[0]?.count || 0 },
                { label: 'Administrateur', value: data[1]?.count || 0 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
