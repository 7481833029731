import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import './styleFacture.css';
// @mui
import { 
  Container, 
  Stack, 
  Typography, 
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Button,
  Input,
  InputAdornment,
  TablePagination,
  Card, CardContent
} from '@mui/material';
import { Badge } from 'rsuite';
import { Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import Image from '../components/assets/AGS_logo_derive.png';
import { apiPanier, entete, apiFacture, notify } from '../components/API_URL/ApiUrl';
import { useParams } from 'react-router-dom';

export default function DetailFacturePage() {
    const [status, setStatus] = useState();
    const [search, setSearch] = useState("");
    const [livraison, setLivraison] = useState([]);
    const [livraisonInfo, setLivraisonInfo] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [etat, setEtat] = useState("");
    const [chargeId, setChargeId] = useState(null);
    const [userID, setUserID] = useState(null);
    const { id } = useParams();

    const idUse = localStorage.getItem('id');

    // Afficher le contenu facture
    const allPanierPVByIdUser = async () => {
        try {
            const response = await apiPanier.get(`/${idUse}/${id}/Valider`, {
                headers: {
                    entete
                }
            });
      
            console.log(response.data.paniers);
            console.log(response.data.paniers[0].PanierInfoPointVente);
      
            setLivraison(response.data.paniers);
            setUserID(response.data.paniers[0].userID);
            setLivraisonInfo(response.data.paniers[0].PanierInfoPointVente);
            setUserInfo(response.data.paniers[0].User);
            setEtat(response.data.paniers[0]);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };    
    const createdAt = new Date(livraisonInfo.createdAt);

    const gradientStyle = {
        // background: 'linear-gradient(to right, #00b09b, #96c93d)',
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        color: '#fff',
        padding: '2px 10px',
    };
    const gradientStyleSuccess = {
        background: 'linear-gradient(to right, #00b09b, #96c93d)',
        color: '#fff',
        padding: '2px 10px',
    };

    useEffect(() => {
        allPanierPVByIdUser();
    }, [])

  return (
    <>
        <Helmet>
        <title> FACTURE DETAILS| AGS </title>
        </Helmet>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Detail Facture
                </Typography>
            </Stack>
            
                <div className='bodyAdmin' style={{width: "100%"}}>
                    <div className="headerFacture">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>FACTURE</h1>
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>{livraisonInfo.ref_facture}</h1>
                        </div>
                        <div className="infoUserFacture">
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>{livraisonInfo.nomclient}</h1>
                            <span>{livraisonInfo.telclient}</span>
                            <span>{livraisonInfo.emailclient}</span>
                        </div>
                        <div>
                            <img src={Image} width={150} height={90} alt='logo' style={{float: 'right'}}/>
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>{createdAt.toLocaleDateString()}</h1>
                        </div>
                    </div>

                    <div style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}>
                        <Badge
                            style={etat.etat === "En attente" ? gradientStyle : gradientStyleSuccess}
                            content={etat.etat === "En attente" ? "Facture non validée" : "Facture validée"}
                        />
                    </div>


                    <div style={{padding: "10px 0px",}}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{padding: "8px 5px"}}>#</th>
                                    <th style={{padding: "8px 5px"}}>DESIGNATION</th>
                                    <th style={{padding: "8px 5px"}}>QUANTITE</th>
                                    <th style={{padding: "8px 5px"}}>PRIX UNITAIRE</th>
                                    <th style={{padding: "8px 5px"}}>SOUS TOTALE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    livraison.map((item, index) => (
                                        <tr>
                                            <td style={{padding: "8px 5px"}}>{index + 1}</td>
                                            <td style={{padding: "8px 5px"}}>{item.nomarticle}</td>
                                            <td style={{padding: "8px 5px"}}>{item.qte}</td>
                                            <td style={{padding: "8px 5px"}}>{item.prix}</td>
                                            <td style={{padding: "8px 5px"}}>{item.totalByArcticle}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan={4}>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            Total Hors Taxe
                                        </h2>
                                    </td>
                                    <td>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            {livraisonInfo.preTotal}
                                        </h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            Total TTC
                                        </h2>
                                    </td>
                                    <td colSpan={2}>
                                        <label style={{fontWeight: "10", fontSize: "10px", textAlign: "center", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            Prix d'expédition: {livraisonInfo.prixExpediton} Fcfa
                                        </label>
                                    </td>
                                    <td>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            {livraisonInfo.prixTotal}
                                        </h2>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="headerFacture">
                        <NavLink className="btnFacture" to={"/dashboard/facture"} style={{background: "gray", textDecoration: 'none', color: "#FFFFFF"}}>
                            <BsChevronLeft/>
                            Retour
                        </NavLink>
                        <NavLink to={`/dashboard/facturepdf/${id}`} className='btnFacture' style={{textDecoration: "none", color: "#FFFFFF"}}>
                            Imprimer
                        </NavLink>
                    </div>
                </div>
        </Container>
    </>
  )
}
