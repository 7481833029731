// @mui
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
// component
import Iconify from '../../../components/iconify';
import { FaFileInvoiceDollar } from 'react-icons/fa';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function CartWidget() {
  // Dispatch
  const dispatch = useDispatch();
  const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);
    

  return (
    <NavLink to={'/dashboard/facture'} style={{textDecoration: "none"}}>
      <StyledRoot>
        <Badge showZero badgeContent={addedItems.length} color="error" max={99}>
          <FaFileInvoiceDollar size={25} />
        </Badge>
      </StyledRoot>
    </NavLink>
  );
}
