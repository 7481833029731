import axios from "axios";
import { toast } from 'react-toastify';


// Entete
export const entete = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}
export const entetes = {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}

export const notify = (type, text) => {
    switch (type) {
        case 'success':
            toast.success(text)
            break;
        case 'warning':
            toast.warning(text)
            break;
        case 'info':
            toast.info(text)
            break;
        default:
            toast.error(text)
            break;
    }
}


export const url = "https://backend.africaglobaleservice.com";
export const urls = "https://backend.africaglobaleservice.com";
// export const urlLocal = "http://localhost:4000";
export const urlLocal = "https://backend.africaglobaleservice.com";

// AUTH
export const apiAuth = axios.create({
    // baseURL: `${url}/api/login`
    baseURL: `${urlLocal}/api/login`
})

// SIGN UP
export const apiSignUp = axios.create({
    // baseURL: `${url}/api/user`
    baseURL: `${urlLocal}/api/user`
})

// USERS
export const apiUser = axios.create({
    // baseURL: `${url}/api/userAdmin`
    baseURL: `${urlLocal}/api/userAdmin`
})

// USER PERMISSION
export const apiUserPermission = axios.create({
    // baseURL: `${url}/api/permission`
    baseURL: `${urlLocal}/api/permission`
})

// SIGN UP
export const apiContactMail = axios.create({
    // baseURL: `${url}/api/email`
    baseURL: `${urlLocal}/api/email`
})

// SOUS CATEGORIE
export const apiSousCat = axios.create({
    // baseURL: `${url}/api/souscategorie`
    baseURL: `${urlLocal}/api/souscategorie`
})

// CATEGORIE
export const apiCategorie = axios.create({
    // baseURL: `${url}/api/categorie`
    baseURL: `${urlLocal}/api/categorie`
})

// ARTICLE
export const apiArticle = axios.create({
    // baseURL: `${url}/api/article`
    baseURL: `${urlLocal}/api/article`
})

// ARTICLE BY CATEGORIE
export const apiArticleByCategorie = axios.create({
    // baseURL: `${url}/api/article/categorie`
    baseURL: `${urlLocal}/api/article/categorie`
})

// PANIER
export const apiPanier = axios.create({
    // baseURL: `${url}/api/panier`
    baseURL: `${urlLocal}/api/panier`
})

// PANIER POINT DE VENTE
export const apiPanierPointVente = axios.create({
    // baseURL: `${url}/api/panier`
    baseURL: `${urlLocal}/api/panierpointvente`
})

// STOCK
export const apiStock = axios.create({
    // baseURL: `${url}/api/stock`
    baseURL: `${urlLocal}/api/stock`
})

// STOCK BY ARTICLE
export const apiStockByArticle = axios.create({
    // baseURL: `${url}/api/stock`
    baseURL: `${urlLocal}/api/stock/article`
})

// A PROPOS
export const apiApropos = axios.create({
    // baseURL: `${url}/api/apropos`
    baseURL: `${urlLocal}/api/apropos`
})

// A PROPOS
export const apiContenuImg = axios.create({
    // baseURL: `${url}/api/dataApp`
    baseURL: `${urlLocal}/api/dataApp`
})

// SLIDE
export const apiContenuSlide = axios.create({
    // baseURL: `${url}/api/slide`
    baseURL: `${urlLocal}/api/slide`
})

// Favoris
export const apiFavoris = axios.create({
    // baseURL: `${url}/api/favoris`
    baseURL: `${urlLocal}/api/favoris`
})

// Favoris By Article
export const apiFavorisByArticle = axios.create({
    // baseURL: `${url}/api/favoris/article`
    baseURL: `${urlLocal}/api/favoris/article`
})

// FEDAPAY
export const apiFedapay = axios.create({
    // baseURL: `${url}/api/fedapay/transaction`
    baseURL: `${urlLocal}/api/fedapay/transaction`
})

// RAPPORT
export const apiRapportUser = axios.create({
    // baseURL: `${url}/api/users/count`
    baseURL: `${urlLocal}/api/users/count`
})

// FACTURE
export const apiFacture = axios.create({
    baseURL: `${urlLocal}/api/facture`
})

// LIVRAISON
export const apiLivraison = axios.create({
    baseURL: `${urlLocal}/api/livraison`
})

// Gestion Paiement Menu
export const apiGestionPaiementMenu = axios.create({
    baseURL: `${urlLocal}/api/gestionpaiement`
})

// Gestion Panier PV
export const apiPanierInfoPV = axios.create({
    baseURL: `${urlLocal}/api/panierPv`
})
