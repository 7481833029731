import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer } from 'react-toastify';
import { apiAuth, entete, notify } from '../../../components/API_URL/ApiUrl';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userInfoc, setuserInfoc] = useState({
    email: "",
    password: ""
  });

  /* const handleClick = () => {
    navigate('/dashboard', { replace: true });
  }; */

  const onChangeValue = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
  };

  const submitUser = async (e) => {
    e.preventDefault();
  
    await apiAuth.post('/', {
      entete,
      email: userInfoc.email,
      password: userInfoc.password
    }).then((res) => {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('email', res.data.data.email);
      localStorage.setItem('nom', res.data.data.nom);
      localStorage.setItem('prenom', res.data.data.prenom);
      localStorage.setItem('id', res.data.data.id);
      localStorage.setItem('type', res.data.data.type);
      switch (res.data.data.type ) {
        case 'admin':
          notify('success', 'Connexion réussi');
          navigate('/dashboard', { replace: true });
          break;
        case 'superadmin':
          notify('success', 'Connexion réussi');
          navigate('/dashboard', { replace: true });
          break;
        default:
          navigate('/login', { replace: true });
          notify('warning', 'Attention !! Accès réservé aux utilisateurs autorisés');
          break;
      }
    }).catch((err) => {
      console.log(err);
      notify('warning', "Vérifier vos informations");
    });
  };

  return (
    <>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack spacing={3}>
        <TextField
          name="email"
          type='email'
          label="Email address"
          value={userInfoc.email}
          onChange={onChangeValue}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={userInfoc.password}
          onChange={onChangeValue}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={submitUser}>
        Connexion
      </LoadingButton>
    </>
  );
}
