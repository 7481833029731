import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Container, Table } from 'react-bootstrap';
import { Badge } from 'rsuite';
import Images from '../components/assets/AGS_logo_derive.png'; // Assurez-vous d'importer correctement le composant Image
import { apiPanier, entete } from '../components/API_URL/ApiUrl'; // Assurez-vous d'importer correctement le module apiPanier

export const FacturePdfView = () => {
  const [livraison, setLivraison] = useState([]);
  const [livraisonInfo, setLivraisonInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [etat, setEtat] = useState({});
  const { id } = useParams();

  const idUse = localStorage.getItem('id');

  useEffect(() => {
    const allPanierPVByIdUser = async () => {
      try {
        const response = await apiPanier.get(`/${idUse}/${id}/Valider`, {
          headers: {
            entete: 'valeur de entete',
          },
        });

        const { paniers } = response.data;
        const firstPanier = paniers[0];

        console.log(paniers);
        console.log(firstPanier.PanierInfoPointVente);

        setLivraison(paniers);
        setLivraisonInfo(firstPanier.PanierInfoPointVente);
        setUserInfo(firstPanier.User);
        setEtat(firstPanier);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
      }
    };

    allPanierPVByIdUser();
  }, []);

  const createdAt = new Date(livraisonInfo.createdAt);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#E4E4E4',
      padding: "40px 5px"
    },
    table: {
        width: '100%',
        marginBottom: '10px',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        backgroundColor: '#f2f2f2',
    },
    tableHeaderCell: {
        padding: '8px 5px',
        fontWeight: 'bold',
        width: "25%",
        textAlign: "center",
        fontSize: "12px"
    },
    tableRow: {
        borderBottom: '1px solid #ccc',
        display:'flex',
        flexDirection: 'row'
    },
    tableCell: {
        padding: '8px 5px',
        width: "25%",
        textAlign: "center",
        fontSize: "10px"
    },
    headerFacture: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
    },
    infoUserFacture: {
        display: "flex",
        flexDirection: "column",
    },
    gradientStyleSuccess: {
        background: 'rgb(255, 78, 80)',
        color: 'green',
        padding: '2px 10px',
        fontSize: "12px",
        fontWeight: "bold"
    }
  });

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <Container>
            <View style={styles.headerFacture}>
                <View style={{ width: '100%', textAlign: 'center' }}>
                    <Text style={{ color: 'rgba(178,127,65,255)', fontWeight: 'bolder' }}>FACTURE</Text>
                    <Text style={{ color: 'rgba(178,127,65,255)', fontWeight: 'bolder' }}>{livraisonInfo.ref_facture}</Text>
                </View>
                <View style={styles.infoUserFacture}>
                    <Text style={{ color: 'rgba(178,127,65,255)', fontWeight: 'bolder' }}>{livraisonInfo.nomclient}</Text>
                    <Text>{livraisonInfo.telclient}</Text>
                    <Text>{livraisonInfo.emailclient}</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <Image src={Images} alt="logo" style={{ width: "100px", height: "50px" }} />
                    <Text style={{ color: 'rgba(178,127,65,255)', fontWeight: 'bolder' }}>{createdAt.toLocaleDateString()}</Text>
                </View>
            </View>

            <View style={{ width: '100%', textAlign: 'center' }}>
                <Text style={styles.gradientStyleSuccess}>
                    Facture payée
                </Text>
            </View>

            <View style={{ padding: '10px 0px' }}>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={[styles.tableHeaderCell, {width: "5%"}]}>
                            <Text>#</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>DESIGNATION</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>QUANTITE</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>PRIX UNITAIRE</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>SOUS TOTALE</Text>
                        </View>
                    </View>

                    {livraison.map((item, index) => (
                    <View key={item.id} style={styles.tableRow}>
                        <View style={[styles.tableCell, {width: "5%"}]}>
                            <Text>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>{item.nomarticle}</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>{item.qte}</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>{item.prix}</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>{item.totalByArcticle}</Text>
                        </View>
                    </View>
                    ))}

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCell, {width: "75%", textAlign: "left"}]}>
                            <Text>Total Hors Taxe</Text>
                        </View>
                        <View style={[styles.tableCell,{ width: "25%"}]}>
                            <Text>{livraisonInfo.preTotal}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCell, {width: "25%", textAlign: "left"}]}>
                            <Text>Total TTC</Text>
                        </View>
                        <View style={[styles.tableCell,{ width: "50%"}]}>
                            <Text style={{fontSize: '8px'}}>Taxe: {livraisonInfo.prixExpediton} Fcfa</Text>
                        </View>
                        <View style={[styles.tableCell,{ width: "25%"}]}>
                            <Text>{livraisonInfo.prixTotal}</Text>
                        </View>
                    </View>

                </View>
            </View>
          </Container>
        </Page>
      </Document>
    </PDFViewer>
  );
};
