import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  TextField
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { apiCategorie, entete, notify } from '../components/API_URL/ApiUrl';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TABLE_HEAD = [
  { id: 'nomcat', label: 'Nom Categorie', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nomcat.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GestionCategoriePage() {
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nomcat');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Modale ADD Categorie
    const [openModal, setOpenModal] = useState(null);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [categorie, setCategorie] = useState([]);
    const [nomcat, setNomCategorie] = useState('');
    const [ids, setIds] = useState(0);
    const [idUpd, setIdUpd] = useState(''); 

    // Modal Update Categorie
    const [showupd, setShowupd] = useState(false);
    const handleCloseupd = () => {
        setShowupd(false)
    };
    const handleShowupd = (e) => {
        setShowupd(true)
    };

    // All Categorie
    const allcategorie = async () => {
        const token = localStorage.getItem('token');
        try {
            apiCategorie.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    // Ajout de Categorie
    const ajoutCategorie = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        if (nomcat) {
            try{
                // alert(libsouscat)
                await apiCategorie.post('/', 
                { 
                    nomcat
                }, {
                    headers: {
                        entete,
                        Authorization: `Bearer ${token}`
                    }
                }).then(res => {
                    console.log(res.data)
                    // alert(res.data.message)
                    setNomCategorie("");
                    handleCloseModal();
                    setCategorie([]);
                    allcategorie();
                    setStatus(true);
                    notify('success', `${res.data.message}`);
                }).catch((err) => {
                    console.log(err);
                    notify('warning', "Une erreur s'est produite lors de la création de la catégorie. Assurez-vous que les champs sont bien renseignés");
                    handleCloseModal();
                })
            } catch (err) {
                console.error(err);
                notify('', `Echec lors de l'ajout de la catégorie. Veuillez rééssayer plutard`)
                handleCloseModal();
            }
        } else {
            notify('', `Vérifiez que tous les champs ne sont pas vide`);
        }
    };

    const getDataById = async () => {
        const token = localStorage.getItem('token');

        try {
            const { data } = await apiCategorie.get(`/${ids}`, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(data);
            setIdUpd(data.data.id)
            setNomCategorie(data.data.nomcat)
        } catch (error) { 
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    }

    // Update de categorie
    const updateCategorie = async (e) => {
        // alert(ids)
        e.preventDefault();
        const token = localStorage.getItem('token');
        
        await apiCategorie.put(`/${ids}`, 
        { 
            nomcat
        },
        {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            notify('success', 'Mise à jour réussi');
            setNomCategorie("");
            setCategorie([]);
            allcategorie();
            setStatus(true);
            handleCloseupd();
        }).catch((err) => {
            console.log(err);
            notify('warning', "Echec de la mise à jour");
            handleCloseupd();
        });
    };

    // Delete categorie
    const deleteConfirm = (ids) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Confirmer la suppression',

        }).then((result) => {
            // Read more about isConfirmed, isDenied below 
            if (result.isConfirmed) {
                deleteArticle(ids);
            } else if (result.isDenied) {
                Swal.fire('Suppression Annuler', '', 'info')
            }
        })
    };
    const deleteArticle = async (ids) => {
        const token = localStorage.getItem('token');
        apiCategorie.delete(`/${ids}`, {
            id: ids,
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            // alert(res.data)
            notify('success', 'Suppression réussi');
            setCategorie([]);
            allcategorie();
        })
    }

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = categorie.map((n) => n.nomcat);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, nomcat) => {
        const selectedIndex = selected.indexOf(nomcat);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, nomcat);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categorie.length) : 0;

    const filteredUsers = applySortFilter(categorie, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;


    useEffect(() => {
        allcategorie();
    }, []);

    useEffect(() => {
        getDataById();
    }, [ids]);

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Helmet>
                <title> Gestion Des Categories | AGS </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Gestion de catégories
                    </Typography>
                    <Button onClick={handleOpenModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Ajouter une catégorie
                    </Button>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={categorie.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, nomcat } = row;
                                        const selectedUser = selected.indexOf(nomcat) !== -1;

                                        return (
                                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell padding="checkbox">
                                                    <Box height={16} />
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {nomcat}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="right">
                                                    <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, id); setIds(id) }}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Pas trouvé
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        Aucun résultat trouvé pour &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={categorie.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleShowupd}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Modifier
                </MenuItem>

                <MenuItem onClick={() => deleteConfirm(ids)} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Supprimer
                </MenuItem>
            </Popover>

            {/* MODAL ADD CATEGORIE */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={3}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Ajouter une Catégorie
                        </Typography>
                        <TextField
                            name="nomcat"
                            type='text'
                            label="Nom catégorie"
                            value={nomcat}
                            onChange={(e) => setNomCategorie(e.target.value)}
                            required
                        />
                        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={ajoutCategorie}>
                            Ajouter
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            {/* MODAL UPDATE CATEGORIE */}
            <Modal
                open={showupd}
                onClose={handleCloseupd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={3}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Modifier une Catégorie
                        </Typography>
                        <TextField
                            name="nomcat"
                            type='text'
                            label="Nom catégorie"
                            value={nomcat}
                            onChange={(e) => setNomCategorie(e.target.value)}
                            required
                        />
                        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateCategorie}>
                            Modifier
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
