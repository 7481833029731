// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const navConfig = [
  {
    title: 'Tableau de bord',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Gestion des Categories',
    path: '/dashboard/categorie',
    icon: icon('ic_user'),
  },
  {
    title: 'Gestion des Sous Categories',
    path: '/dashboard/souscategorie',
    icon: icon('ic_user'),
  },
  {
    title: 'Gestion des Articles',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Facture',
    path: '/dashboard/facture',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;

