import { Navigate, useRoutes, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import GestionCategorie from './pages/GestionCategoriePage';
import GestionSousCategoriePage from './pages/GestionSousCategoriePage';
import Facture from './pages/Facture';
import DetailFacturePage from './pages/DetailFacturePage';
import { FacturePdfView } from './pages/FacturePdfView';
// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('token');

  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'categorie', element: <GestionCategorie /> },
        { path: 'souscategorie', element: <GestionSousCategoriePage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'facture', element: <Facture /> },
        { path: 'detailfacture/:id', element: <DetailFacturePage /> },
        { path: 'facturepdf/:id', element: <FacturePdfView /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const loginRoute = (
    <Routes>
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );

  return token ? routes : loginRoute;
}

