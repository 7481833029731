import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { LoadingButton } from '@mui/lab';
import { 
  Container, 
  Stack, 
  Typography, 
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Button,
  Input,
  InputAdornment,
  TablePagination
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Iconify from '../components/iconify';
import Image from '../components/assets/produit.jpg';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import { apiArticle, entetes, apiCategorie, apiSousCat, entete, apiStock, notify } from '../components/API_URL/ApiUrl';
import { addItemToCard } from './redux/action/Actions';
// mock
import PRODUCTS from '../_mock/products';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openFilter, setOpenFilter] = useState(false);
  const [status, setStatus] = useState();

  // Dispatch
  const dispatch = useDispatch()

  // Data categorie
  const [categorie, setCategorie] = useState([]);

  // Data Sous categorie
  const [sousCat, setSousCat] = useState([]);

  // Data Article
  const [article, setArticle] = useState([]);
  const [ids, setIds] = useState(0);
  const [nomarticle, setNomarticle] = useState('');
  const [description, setDescription] = useState('');
  const [prix, setPrix] = useState('');
  const [prix_promo, setPrix_promo] = useState('');
  const [imgArt, setImg_art] = useState(null);
  const [sousCategorieID, setSousCategorieID] = useState('');
  const [categorieID, setCategorieID] = useState('');

  // Data Stock
  const [stock, setStock] = useState([]);

  // Update article
  const [articleid_upd, setArticleid_upd] = useState('');
  const [nomarticle_upd, setNomarticle_upd] = useState('');
  const [description_upd, setDescription_upd] = useState('');
  const [prix_upd, setPrix_upd] = useState(0);
  const [prix_promo_upd, setPrix_promo_upd] = useState(0);
  const [img_art_upd, setImg_art_upd] = useState('');
  const [sousCategorieID_upd, setSousCategorieID_upd] = useState('');
  const [categorieID_upd, setCategorieID_upd] = useState('');

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [userInfo, setuserInfo] = useState({
    filepreview: null,
  });

  // Modal Article
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal Update Article
  const [showupd, setShowupd] = useState(false);
  const handleCloseupd = () => { setShowupd(false) };
  const handleShowupd = (e) => { setShowupd(true) };

  // Modal Stock
  const [showStock, setShowStock] = useState(false);
  const handleCloseStock = () => setShowStock(false);
  const handleShowStock = () => setShowStock(true);
  const [quantiteStock, setQuantité] = useState(false);
  const [articleID, setArticleId] = useState(false);

  // All article
  const allarticle = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticle.get('/', {
          headers: {
              entete,
              Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
        console.log(res.data.data)
        setArticle(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All articles
 /* const allStock = async () => {
    const token = localStorage.getItem('token');
    try {
      apiStock.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
        console.log(res.data.data)
        setStock(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }; */

  // All les catégories
  const allcategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiCategorie.get('/', {
          headers: {
              entete,
              Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
          console.log(res.data.data)
          setCategorie(res.data.data);
      })
    } catch (error) {
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All sous Categories
  const allsouscategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSousCat.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      })
      .then(res => {
        console.log(res.data.data)
        setSousCat(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // Open Modal Update
  const handleUpdate = (id) => {
    setIds(id);
    handleShowupd();
  };  

  // Open Modal Stock
  const handleStock = (id) => {
    setArticleId(id);
    handleShowStock();
  }; 

  // Add articles
  const ajoutArticle = async (e) => {
    e.preventDefault();
    // e.persist();
    const token = localStorage.getItem('token');

    if (nomarticle && prix) {
      const imgSize = imgArt && imgArt.size;
      if (imgSize && imgSize > 1048576) { // Vérification de la taille de l'image
        notify('warning', "La taille de l'image doit être inférieure ou égale à 1 Mo");
        return;
      }
      // setIsLoading(true);
      const parsedPrix = parseFloat(prix);
      const parsedPrixPromo = parseFloat(prix_promo);
      if(!Number.isNaN(parsedPrix) && !Number.isNaN(parsedPrixPromo) && parsedPrix > parsedPrixPromo){
        try{
          const formData = new FormData();
          formData.append("nomarticle", nomarticle);
          formData.append("description", description);
          formData.append("prix", prix);
          formData.append("prix_promo", prix_promo);
          formData.append("img_art", imgArt);
          formData.append("categorieID", categorieID);
          formData.append("sousCategorieID", sousCategorieID);

          await apiArticle.post('/', formData, {
              headers: {
                entetes,
                Authorization: `Bearer ${token}`
              }
          }).then (res => {
            console.log(res.data);
            setNomarticle("");
            setDescription("");
            setPrix(0);
            setPrix_promo(0);
            setImg_art(null);
            setCategorieID('');
            setSousCategorieID('');
            handleClose();
            setArticle([]);
            allarticle();
            setStatus(true);
            notify('success', `${res.data.message}`);
          }).catch((err) => {
            console.log(err);
            notify('warning', "Une erreur s'est produite lors de la création de l'article. Assurez-vous que les champs sont bien renseignés");
            handleClose();
          })
        } catch (err) {
          console.error(err);
          notify('', `Echec lors de l'ajout de l'article. Veuillez rééssayer plutard`)
          handleClose();
        }
      } else {
        notify('warning', "Vérifiez que le prix normal est supérieur au prix promo et que les prix sont supérieurs à zéro");
      }
    } else {
      notify('warning', "Assurez-vous que tous les champs sont bien renseignés");
    }
  };

  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiArticle.get(`/${ids}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      setArticleid_upd(data.data.id_art);
      setNomarticle(data.data.nomarticle);
      setDescription(data.data.description);
      setPrix(data.data.prix);
      setPrix_promo(data.data.prix_promo);
      setImg_art(data.data.img_art);
      setCategorieID(data.data.categorieID);
      setSousCategorieID(data.data.sousCategorieID);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  const getDataByIdStock = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiStock.get(`/${articleID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      setQuantité(data.data.quantiteStock);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  // Update article
  const updateArticle = async (e) => {
    // alert(ids)
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("nomarticle", nomarticle);
    formData.append("description", description);
    formData.append("prix", prix);
    formData.append("prix_promo", prix_promo);
    formData.append("img_art", imgArt);
    formData.append("categorieID", categorieID);
    formData.append("sousCategorieID", sousCategorieID);
    try{ 
        await apiArticle.put(`/${ids}`, formData,
        {
            headers: {
              entetes,
              Authorization: `Bearer ${token}`
            }
        }).then(res => {
          setNomarticle("");
          setDescription("");
          setPrix(0);
          setPrix_promo(0);
          setImg_art("");
          setCategorieID('');
          setSousCategorieID('');
          handleCloseupd();
          setArticle([]);
          allarticle();
          setStatus(true);
          notify('success', 'Mise à jour réussi');
        }).catch((err) => {
            console.log(err);
            notify('warning', "Echec de la mise à jour");
            handleCloseupd();
        });
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
  };

  // Delete article
  const deleteConfirm = (ids) => {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Confirmer la suppression',

    }).then((result) => {
        // Read more about isConfirmed, isDenied below 
        if (result.isConfirmed) {
            deleteArticle(ids);
        } else if (result.isDenied) {
            Swal.fire('Suppression Annuler', '', 'info')
        }
    })
  };
  const deleteArticle = async (ids) => {
      const token = localStorage.getItem('token');
      try{ 
        apiArticle.delete(`/${ids}`, {
              id: ids,
              headers: {
                entete,
                Authorization: `Bearer ${token}`
              }
          }).then(res => {
            // alert(res.data)
            notify('success', 'Suppression réussi');
            setArticle([]);
            allarticle();
          }).catch((err) => {
            console.log(err);
            notify('warning', "Suppression échoué");
          })
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
          }
      }
  }

  // Add Stock
  const updateStock = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (quantiteStock) {
      try {
        const response = await apiStock.put(`/${articleID}`, { quantiteStock }, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data);   
        setQuantité("");
        setArticleId("");
        handleCloseStock();
        setArticle([]);
        allarticle();
        setStatus(true);
        notify('success', `${response.data.message}`);
      } catch (error) {
        console.error(error);
        setQuantité("");
        setArticleId("");
        handleCloseStock();
        if (error.response && error.response.status === 400 && error.response.data && error.response.data.message && error.response.data.message.includes('existe déjà')) {
          notify('warning', `${error.response.data.message}`);
        } else {
          notify('warning', "Une erreur s'est produite lors de la mise à jour de stock disponible. Assurez-vous que le champ est bien renseigné");
        }
      }
    } else {
      notify('warning', `Vérifiez que tous les champs ne sont pas vide`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Réinitialise la page à 0 lorsque le nombre de lignes par page est modifié
  };  

  // Utilisez slice pour obtenir les articles correspondant à la pagination actuelle
  const paginatedArticles = article.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    // Basket
    const addItem = (item) => {
      dispatch(addItemToCard(item));
      // const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);
    };

  useEffect(() => {
    allarticle();
    allcategorie();
    allsouscategorie();
    getDataByIdStock();
    // allStock();
  }, []);

  useEffect(() => {
    getDataById();
  }, [ids]);

  return (
    <>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title> Gestion d'article | AGS </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Gestion des Articles
          </Typography>

          <Button onClick={handleShow} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Ajouter un article
          </Button>
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList 
          articles={paginatedArticles} 
          onClickUpdate={handleUpdate} 
          onClickDelete={deleteConfirm}
          onClickStock={handleStock}
          addcard={addItem}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
          component="div"
          count={Math.ceil(article.length / rowsPerPage)}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <ProductCartWidget />
      </Container>
      {/* MODAL ADD ARTICLE */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" spacing={3}>
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Ajouter un Article
              </Typography>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={categorieID}
                  onChange={(e) => {
                    setCategorieID(e.target.value);
                    setSousCategorieID(0);
                  }}
                  label="Catégorie"
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir une catégorie</em>
                  </MenuItem>
                  {categorie.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.nomcat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={sousCategorieID}
                  onChange={(e) => setSousCategorieID(e.target.value)}
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir une sous catégorie</em>
                  </MenuItem>
                  {sousCat
                    .filter((item) => item.categoriId === parseInt(categorieID, 10))
                    .map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.libsouscat}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                name="nomarticle"
                type="text"
                label="Nom article"
                variant="standard"
                value={nomarticle}
                onChange={(e) => setNomarticle(e.target.value)}
                required
              />

              <TextField
                name="description"
                type="text"
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                variant="standard"
              />

              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Prix article</InputLabel>
                <Input
                  id="standard-adornment-amount"
                  type='number'
                  value={prix}
                  onChange={(e) => setPrix(e.target.value)}
                  minRows={0}
                  endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                  required
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Prix Promo article</InputLabel>
                <Input
                  id="standard-adornment-amount"
                  type='number'
                  value={prix_promo}
                  onChange={(e) => setPrix_promo(e.target.value)}
                  minRows={0}
                  endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                  required
                />
              </FormControl>

              <TextField
                name="img_art"
                type="file"
                label="Image article"
                variant="standard"
                onChange={(e) => {
                    setImg_art(e.target.files[0]);
                    setuserInfo({ 
                        ...userInfo, 
                        filepreview: URL.createObjectURL(e.target.files[0]) 
                    });
                }} 
                required
              />
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={ajoutArticle}>
                Ajouter
              </LoadingButton>
            </Stack>
            <Box>
              {userInfo.filepreview !== null ?
                <div className='sectionViewImage'>
                    <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                        <img src={userInfo.filepreview} alt='img' width="100%" height="100%"/>
                    </div>
                </div>
                :
                <div className='sectionViewImage'>
                    <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                        <img src={Image} alt='img' width="100%" height="100%"/>
                    </div>
                </div>
              }
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL UPDATE ARTICLE */}
      <Modal
        open={showupd}
        onClose={handleCloseupd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row">
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Modifier l'article
              </Typography>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={categorieID}
                  onChange={(e) => setCategorieID(e.target.value)}
                  label="Catégorie"
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir une catégorie</em>
                  </MenuItem>
                  {categorie.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.nomcat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={sousCategorieID}
                  onChange={(e) => setSousCategorieID(e.target.value)}
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir une sous catégorie</em>
                  </MenuItem>
                  {sousCat.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.libsouscat}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="nomarticle"
                type="text"
                label="Nom article"
                variant="standard"
                value={nomarticle}
                onChange={(e) => setNomarticle(e.target.value)}
                required
              />

              <TextField
                name="description"
                type="text"
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                variant="standard"
              />

              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Prix article</InputLabel>
                <Input
                  id="standard-adornment-amount"
                  type="number"
                  value={prix}
                  onChange={(e) => setPrix(e.target.value)}
                  minRows={0}
                  endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                  required
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Prix Promo article</InputLabel>
                <Input
                  id="standard-adornment-amount"
                  type="number"
                  value={prix_promo}
                  onChange={(e) => setPrix_promo(e.target.value)}
                  minRows={0}
                  endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                  required
                />
              </FormControl>

              <TextField
                name="img_art"
                type="file"
                label="Image article"
                variant="standard"
                onChange={(e) => {
                  setImg_art(e.target.files[0]);
                  setuserInfo({ 
                    ...userInfo, 
                    filepreview: URL.createObjectURL(e.target.files[0]) 
                  });
                }} 
                required
              />
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateArticle}>
                Modifier
              </LoadingButton>
            </Stack>
            <Box>
              {userInfo.filepreview !== null ? (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={userInfo.filepreview} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              ) : (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={Image} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              )}
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL STOCK */}
      <Modal
        open={showStock}
        onClose={handleCloseStock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Stack spacing={3}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Modifier Stock
                </Typography>

                <TextField
                  name="libsouscat"
                  label="Quantité"
                  value={quantiteStock}
                  onChange={(e) => setQuantité(e.target.value)}
                  type="number"
                  required
                />
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateStock}>
                  Modifier
                </LoadingButton>
            </Stack>
        </Box>
      </Modal> 
    </>
  );
}
